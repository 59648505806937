.verticalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.summaryTabsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  width: inherit;
  padding: 0 120px;
}

.scrollableContainer {
  box-sizing: border-box;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.summaryTabsContainer .scrollableContainer::-webkit-scrollbar {
  width: 0; /* Hide scrollbar for Chrome */
  height: 0; /* Hide scrollbar for Chrome */
}

.summaryTabName {
  text-align: center;
  margin-bottom: 0;
  font-weight: normal;
}

.statusBadge {
  border-radius: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.statusBadgeText {
  font-family: Lato;
  font-weight: bold;
  text-align: center;
}

.actionButtonsContainer {
  padding-top: 6px;
  padding-bottom: 6px;
  margin-left: 10px;
  margin-right: 10px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
}

.actionButtonsContainerForVert {
  margin-top: -10px;
  margin-left: 14px;
  margin-bottom: 10px;
  display: flex;
  justify-content: start;
}
