.header {
  text-transform: uppercase;
}

.finalItemPrice {
  font-weight: 600;
}

.imageContainer {
  padding: 15px;
}

.itemPrice {
  font-size: 1.1em;
}

.priceDifferenceDescription {
  font-weight: 600;
  font-size: 1.1em;
}

.quantityIndicator {
  font-size: 0.85714286em;
  margin-top: 10px;
}

.outOfStock {
  color: #db2828;
}

.selectionRow {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0;
}

.submitRow {
  padding-top: 20px;
  padding-bottom: 5px;
}

.taxIncluded {
  font-size: 1em;
}

.variantButton {
  margin-bottom: 5px;
  margin-right: 3px;
}

.submitRowContainer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 14px;
}
