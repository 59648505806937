.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.contentContainer {
  background: white;
  padding: 16px;
  text-align: center;
  border-radius: 15px;
}

.content {
  flex-direction: column;
  display: flex;
  flex: 1;
  justify-content: center;
}

.form {
  padding: 20px 0;
}

.termsContainer {
  margin-top: 10px;
}

.returningAGiftButton {
  background-color: transparent !important;
  border: none;
  color: inherit !important;
  text-decoration: underline !important;
  padding-left: 0 !important;
  padding-top: 30px !important;
}
