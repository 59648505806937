.headerContainer {
  align-items: center;
  border-top-left-radius: 2.85px;
  border-top-right-radius: 2.85px;
  display: flex;
  font-size: 1.3em;
  font-weight: bold;
  justify-content: space-between;
  line-height: 1.28571429em;
  margin: 20px;
  margin-bottom: 0;
}

.headerIcon {
  cursor: pointer;
}

@media (min-width: 601px) {
  .headerContainer {
    margin: 24px 24px 12px;
  }
}
