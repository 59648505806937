.cardElement {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  padding: 9.5px 14px;
}

.noteContainer {
  margin-top: 7.5px !important;
  width: 100%;
}
