.container {
  position: relative;
  box-shadow:
    0 4px 6px -1px rgb(0 0 0 / 0.1),
    0 2px 4px -2px rgb(0 0 0 / 0.1);
  /*
   * The below attributes are used to set container to be a perfect
   * square, and then vertically+horizontally align the element within.
   */
  display: flex;
}
