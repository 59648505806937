.itemTitle {
  text-transform: uppercase;
}

.disabledItemTitle {
  text-transform: uppercase;
  color: gray;
}

.itemMetaText {
  font-size: 1em;
}

.unreturnableReason {
  margin-top: 10px;
}

@media (max-width: 600px) {
  .itemTitle {
    font-size: 0.9em;
  }

  .itemMetaText {
    font-size: 0.9em;
  }
}
