.headerContainer {
  margin-bottom: 10px;
}

.contentContainer {
  background: white;
  padding: 30px 10px;
  text-align: center;
  border-radius: 15px;
}

@media (min-width: 768px) {
  .contentContainer {
    width: 60% !important;
  }
}

@media (min-width: 1024px) {
  .contentContainer {
    width: 40% !important;
  }
}

@media (min-width: 1280px) {
  .contentContainer {
    width: 35% !important;
  }
}

@media (min-width: 1536px) {
  .contentContainer {
    width: 28% !important;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
}

.descriptionContainer {
  display: flex;
  justify-content: center;
}

.description {
  width: 90%;
}

.renewTimelineContainer {
  width: 60%;
  border-top: 2px dotted #7c7e82;
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
}

.renewTimelineContainer li {
  position: relative;
  margin-top: 15px;
  color: #7c7e82;
}

.renewTimelineContainer li:first-child {
  margin-left: -70px;
}

.renewTimelineContainer li:last-child {
  margin-right: -70px;
}

.renewTimelineContainer li:after {
  content: "";
  width: 20px;
  height: 20px;
  background: #d6d6d6;
  position: absolute;
  border-radius: 10px;
  top: -26px;
  left: 50%;
  transform: translatex(-50%);
}

.renewTimelineContainer li.activeTimeline {
  color: #377645;
}

.renewTimelineContainer li.activeTimeline:after {
  background: #377645;
}
