.header {
  margin: 30px 0 20px 0;
  padding: 1rem;
}

@media (min-width: 425px) {
  .header {
    margin: 20px 0;
  }
}

.returnMethodTypeSelectorHeader {
  margin: 30px 0 20px 0;
  padding: 0;
}
