.itemSummary {
  font-size: 12px;
}

.header {
  font-weight: bold;
  font-size: 14px;
}

.subheader {
  color: gray;
}

.subheader p {
  margin: 0 !important;
}

.image {
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 8px;
}

.returnItemsGroupsContainer:not(:last-child) {
  margin-bottom: 20px;
}
