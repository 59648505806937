.reselectImageBtnContainer {
  display: flex !important;
  flex-direction: row-reverse !important;
}
.headerContainer {
  display: flex !important;
  flex-direction: column;
  align-items: start !important;
}
.submitRow {
  padding-top: 20px;
}

.uploadedImage {
  position: relative;
}

.crossIcon {
  position: absolute;
  top: 0;
  right: 10px;
  cursor: pointer;
}

.uppyPreviewImage img {
  /* translateZ(0) is causing an overlay over the image */
  transform: none !important;
}
