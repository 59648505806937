.accordionTitle {
  display: inline;
  font-weight: bold;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}

.amount {
  float: right;
}

.subHeader {
  font-style: italic;
  font-weight: lighter;
  color: gray;
  font-size: 0.95rem;
  padding-left: 1.5rem;
  width: 80%;
}
