.header {
  font-weight: bold;
  padding-bottom: 0.5rem;
}

.helpText {
  display: block;
  color: grey;
  font-style: italic;
  font-weight: normal;
  font-size: 0.95rem;
}

.accordionTitle {
  display: inline;
  font-weight: bold;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}
