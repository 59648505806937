.buttonContainer {
  align-items: center;
  border-bottom: 0.75px solid rgba(34, 36, 38, 0.15);
  color: #767676;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 10px 5px;
}

.buttonContainer:last-child {
  border-bottom: none;
}

.buttonContainer:hover {
  color: var(--primary-color);
}
