.container {
  align-items: center;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex: 1;
}

.labelContainer {
  padding-bottom: 10px;
}

.payableText {
  color: #db2828;
  margin-top: -5px;
}

.textBox {
  flex: 2;
  text-align: left;
  padding: 0 10px 0 5px;
}

.courierLogo {
  width: 50px;
}

.rightTextBox {
  flex: 1;
  text-align: right;
  margin-right: 6px;
}

.titleContainer {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}
