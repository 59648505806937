.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex-direction: column;
  display: flex;
  flex: 1;
  justify-content: center;
}

.footer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.footerText {
  color: #fff;
}

.form {
  padding: 20px 0;
}

.termsContainer {
  margin-top: 10px;
}

.trackingNumber {
  margin-bottom: 10px;
  width: 90%;
}
