:root {
  --primary-color: #fbbd08;
  /* BottomSheet styles configuration */
  --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
  --rsbs-bg: #fff;
  --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);
  --rsbs-max-w: auto;
  --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right);
  --rsbs-overlay-rounded: 16px;
  /* BottomSheet styles configuration */
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto !important;
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input {
  -webkit-appearance: none;
}

/* Disable blur (triggered by modal) on Google Maps locations dropdown */
.pac-container.pac-logo {
  filter: none !important;
}

/*
*  On mobile, some browsers (e.g. Safari on iOS) automatically zooms in when the user focuses on an input element
*  that has a font size smaller than 16px.
*  This is to prevent that.
*/
@media screen and (max-width: 768px) {
  ui.form input[type="color"],
  .ui.form input[type="datetime"],
  .ui.form input[type="datetime-local"],
  .ui.form input[type="email"],
  .ui.form input[type="month"],
  .ui.form input[type="number"],
  .ui.form input[type="password"],
  .ui.form input[type="search"],
  .ui.form input[type="tel"],
  .ui.form input[type="text"],
  .ui.form input[type="url"],
  .ui.form input[type="week"],
  .ui.form textarea,
  .ui.form select {
    font-size: 16px;
  }
}

.ui.accordion .title .dropdown.icon:before {
  font-family: "Accordion";
}
