.returnItemsSelectionContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: inherit;
}

.submitContainer {
  padding-top: 10px;
}

.returnableDeadline {
  text-align: center;
  margin-top: -30px;
  margin-bottom: 30px;
}

.pastReturnsButton {
  color: #0055bc;
  cursor: pointer;
  text-decoration: underline;
  font-style: italic;
}

.pastReturnsButton:hover {
  color: #0055bc;
  text-decoration: underline;
}
