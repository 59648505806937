.addressFieldsHeaderContainer {
  margin-bottom: 10px;
  margin-top: 16px;
  border-bottom: 1px solid #dfe3e8;
}

.addressFieldsHeaderMainPage {
  margin-bottom: 10px;
  padding-bottom: 0.5rem;
}
