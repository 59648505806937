.buttonContainer {
  margin-top: 5px;
}

.buttonText {
  text-transform: uppercase;
}

.statusesContainer {
  display: flex;
  flex-direction: column;
}
