.outerContainer {
  position: relative;
  z-index: 10;
  top: 65px;
}

.innerContainer {
  left: 0;
  padding: 5px;
  position: absolute;
  right: 0;
  top: 0;
}
