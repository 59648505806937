.variantSelectorModal {
  margin: 10px;
  height: 85vh;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.headerContainer {
  align-items: center;
  border-top-left-radius: 2.85px;
  border-top-right-radius: 2.85px;
  display: flex;
  font-size: 1.3em;
  font-weight: bold;
  justify-content: space-between;
  line-height: 1.28571429em;
  gap: 10px;
}

.variantSelectorContainer {
  padding: 15px 0 20px;
  overflow-y: auto;
  overflow-x: hidden;
}
.variantSelectorContainer::-webkit-scrollbar:vertical {
  width: 3px;
}
.variantSelectorContainer::-webkit-scrollbar-track {
  background-color: transparent;
}

@media (min-width: 601px) {
  .variantSelectorModal {
    height: max-content;
    margin: 25px;
  }
}
