.headerContainer {
  align-items: center;
  border-top-left-radius: 2.85px;
  border-top-right-radius: 2.85px;
  display: flex;
  font-size: 1.3em;
  font-weight: bold;
  justify-content: space-between;
  line-height: 1.28571429em;
  padding: 12.5px 15px;
}

.headerIcon {
  cursor: pointer;
}
