.headerContainer {
  align-items: flex-start;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  border-top-left-radius: 2.85px;
  border-top-right-radius: 2.85px;
  display: flex;
  font-size: 1.3em;
  font-weight: bold;
  justify-content: space-between;
  line-height: 1.28571429em;
  padding: 12.5px 15px;
}

.headerIcon {
  cursor: pointer;
}

.subheader {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.4285em;
}
