.exchangeProductSelectorModal {
  margin: 20px;
  height: 85vh;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.productGridContainer {
  flex-grow: 1;
  overflow: auto;
}
.productGridContainer::-webkit-scrollbar:vertical {
  width: 3px;
}
.productGridContainer::-webkit-scrollbar-track {
  background-color: transparent;
}
.productGridContainer::-webkit-scrollbar:horizontal {
  width: 0;
  height: 0;
}

.variantSelectorContainer {
  padding: 20px 0 20px;
  overflow-y: auto;
  overflow-x: hidden;
}
.variantSelectorContainer::-webkit-scrollbar:vertical {
  width: 3px;
}
.variantSelectorContainer::-webkit-scrollbar-track {
  background-color: transparent;
}

.searchView {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10px;
  font-size: 13px;
  line-height: 1.5rem;
}

.searchBar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.loadMoreButtonContainer {
  display: flex;
  justify-content: center;
}

.productCardGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: row;
  gap: 10px;
  margin: 10px 0 10px;
}

.productCardGrid .price {
  margin-right: 10px;
}

.productCardGrid .compareAtPrice {
  white-space: nowrap;
  color: #bf2e35;
}

.tabsBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}

.tabsBar .scrollableContainer {
  box-sizing: border-box;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}
.tabsBar .scrollableContainer::-webkit-scrollbar {
  width: 0; /* Hide scrollbar for Chrome */
  height: 0; /* Hide scrollbar for Chrome */
}
.headerContainer {
  align-items: center;
  border-top-left-radius: 2.85px;
  border-top-right-radius: 2.85px;
  display: flex;
  font-size: 1.3em;
  font-weight: bold;
  justify-content: space-between;
  line-height: 1.28571429em;
  gap: 10px;
}

.leftArrowContainer {
  border-bottom: 2px solid rgba(34, 36, 38, 0.15);
  padding-right: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rightArrowContainer {
  border-bottom: 2px solid rgba(34, 36, 38, 0.15);
  padding-left: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.headerIcon {
  cursor: pointer;
}

.footerSection {
  font-size: 13px;
  text-align: center;
  color: #444444;
  margin: 20px 0px;
}

.footerDescription {
  margin-top: 10px;
}

@media (min-width: 601px) {
  .variantSelectorContainer {
    overflow-y: hidden;
    height: max-content;
  }

  .exchangeProductSelectorModal {
    margin: 24px;
    height: 90vh;
  }

  .searchView {
    padding: 10px;
    font-size: 15px;
    line-height: 1.8rem;
  }

  .headerContainer {
    gap: 15px;
  }

  .footerSection {
    font-size: 15px;
  }
}

.searchButtonContainer {
  flex-grow: 1;
  border-radius: 20px;
  height: 40px;
  padding: 0 16px 0;
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  gap: 8px;
  cursor: text;
}

.searchButtonContainer:hover {
  background-color: #e5e5e5;
}

.searchButtonContainer:focus-within {
  background-color: #e5e5e5;
}

.searchButtonContainer input::placeholder {
  color: #ccc;
  opacity: 1;
}

.searchButtonContainer:hover input::placeholder {
  color: #8b8b8b;
  opacity: 1;
}

.searchButtonContainer input,
.searchButtonContainer input:focus {
  font-size: 12px;
  font-family: "-apple-system", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  background: none;
  outline: none;
  border: none;
  flex: 1 1 auto;
  width: 0;
  line-height: 1;
}

@media only screen and (min-width: 601px) {
  .searchButtonContainer input,
  .searchButtonContainer input:focus {
    font-size: 14px;
  }
}
