.buttonContainer {
  padding: 5px 0;
}

.buttonContentContainer {
  padding: 8px;
  display: flex;
  flex-direction: column;
}

.firstLine {
  display: flex;
  flex-direction: row;
  font-weight: 700;
  justify-content: space-between;
  align-items: top;
}

.bonusCreditBadge {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  row-gap: 8px;
  align-items: top;
}

.buttonDescription {
  margin-top: 10px;
  font-weight: 500;
  text-align: left;
}

.lowPriorityButton {
  box-shadow: none !important;
  border: solid 0.5px !important;
  color: black !important;
  background-color: white !important;
}

.lowPriorityButton:hover,
.highPriorityButton:hover {
  box-shadow:
    0px 1px 3px 0px #bcbdbd,
    0px 0px 0px 1px #d4d4d5 !important;
  transform: translateY(-1px);
  transition: 2s ease-in-out;
}
