.bankingDetailsHeaderContainer {
  margin-top: 16px;
  margin-bottom: 10px;
  border-bottom: 1px solid #dfe3e8;
}

.bankingDetailsHeaderMainPage {
  margin-top: 16px;
  padding-bottom: 0.5rem;
  margin-bottom: 10px;
}
