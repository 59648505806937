.chargeAdjustmentRow {
  color: gray;
  font-weight: 400;
  padding-bottom: 0 !important;
}

.summaryGrid {
  font-weight: bold;
  padding-left: 1.5rem !important;
  padding-top: 1rem !important;
}

.summaryDivider {
  margin-top: -18px;
}
