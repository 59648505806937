.itemTitle {
  text-transform: uppercase;
}

.itemMetaText {
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 0px;
  font-size: 1em;
}

.itemTitleWithBundleReturn {
  text-transform: uppercase;
  color: #1e70bf;
  font-size: 1em;
  line-height: 1.4285em;
  margin-bottom: 0px;
}

.disabledItemTitleWithBundleReturn {
  text-transform: uppercase;
  color: gray;
  font-size: 1em;
  line-height: 1.4285em;
  margin-bottom: 0px;
}

.unreturnableReason {
  margin-top: 10px;
}

.accordionDropdownIcon {
  font-family: "Accordion" !important;
}

@media (max-width: 600px) {
  .itemTitleWithBundleReturn {
    font-size: 0.9em;
    margin-bottom: 5px;
  }

  .itemMetaText {
    font-size: 0.9em;
    margin-bottom: 5px;
  }
}
