.backContainer {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 0 10px;
  position: absolute;
  width: 100%;
}

.backLink {
  color: #4183c4;
  cursor: pointer;
}

.backLink:hover {
  color: #1e70bf;
}

.logo {
  cursor: pointer;
  height: 40px;
  margin: 10px 0px;
  z-index: 1;
}

.navbar {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 0 !important;
  position: relative;
  border-bottom: 0 !important;
}
