.headerContainer {
  align-items: center;
  display: flex;
  justify-content: end;
  font-size: 1.3em;
  font-weight: bold;
  line-height: 1.28571429em;
  margin: 20px;
  margin-bottom: 0;
}

.headerIcon {
  cursor: pointer;
}

@media (min-width: 601px) {
  .headerContainer {
    margin: 24px 24px 12px;
  }
}
