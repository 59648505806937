.addressHeaderContainer {
  margin: 20px 0 15px;
}

.submitContainer {
  padding-top: 10px;
}

.trackingNumberContainer {
  width: 50%;
}
