.instantExchangeSection {
  font-weight: bold;
  padding-top: 1rem !important;
  color: gray;
  padding-left: 1.5rem !important;
}

.subTotalDue {
  padding: 0.5rem 1rem;
}

.totalDue {
  color: black;
}

.chargeAdjustment {
  padding-bottom: 0 !important;
  font-weight: 400;
}
