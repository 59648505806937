.itemSummary {
  font-size: 0.9rem;
}

.header {
  font-weight: bold;
  font-size: 0.9rem;
}

.subheader {
  color: gray;
}

.subheader p {
  margin: 0 !important;
}

.image {
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 8px;
}
