.main {
  background-image: linear-gradient(to bottom, rgba(68, 68, 68, 0.5), rgba(68, 68, 68, 0.5));
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 50px);
  width: 100vw;
  position: relative;
}

.picture {
  position: absolute;
  z-index: -1;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
}

.footer {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  position: absolute;
  width: 100%;
}

.footerText {
  color: #fff;
  margin-bottom: 5px;
}
