.ellipsisWhenOverFlow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.returningItem {
  color: gray;
  font-style: italic;
}

.accordionTitle {
  display: inline;
  font-weight: bold;
  font-size: 14px;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
}
