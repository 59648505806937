.cardElement {
  align-items: center;
  border: 1px solid #2185d0;
  border-radius: 4px;
  color: #2185d0;
  display: flex;
  justify-content: space-between;
  padding: 9.5px 14px;
}

.icon {
  cursor: pointer;
}
