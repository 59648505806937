.header {
  padding-bottom: 1rem;
  display: flex !important;
  justify-content: space-between;
  flex-direction: row;
}

.headerLink {
  text-decoration: underline;
  font-size: 1rem;
}
.shippingMethodInfo {
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}

.subHeader {
  text-decoration: underline;
}

.gridColumn {
  padding-top: 1rem !important;
}
