.mobileViewContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: inherit;
}

.continueButtonContainer {
  padding-top: 0px;
  padding-bottom: 0px;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 999;
}
