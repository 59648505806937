.contactDetailsHeaderContainer {
  margin-top: 16px;
  margin-bottom: 10px;
  border-bottom: 1px solid #dfe3e8;
}

.contactDetailsHeaderMainPage {
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
  margin-top: 16px;
  margin-bottom: 10px;
}
