.buttonContainer {
  padding: 5px 0;
}

.headerContainer {
  padding: 20px 0;
}

.lowPriorityNormalReturnMethodButton {
  padding: 0 0 8px !important;
  box-shadow: none !important;
  font-style: italic !important;
  font-weight: bold !important;
  text-decoration: underline !important;
  &:hover {
    transform: translateY(-1px);
    transition: 2s ease-in-out;
  }
}

.highPriorityNormalReturnMethodButton {
  font-weight: bold !important;
  &:hover {
    box-shadow:
      0px 1px 2px 0px #bcbdbd,
      0px 0px 0px 1px #d4d4d5 !important;
    transform: translateY(-0.5px);
    transition: 0.5s;
  }
}
