.headerContainer {
  margin-bottom: 10px;
}

.contentContainer {
  background: white;
  padding: 30px 10px;
  text-align: center;
  border-radius: 15px;
}

@media (min-width: 768px) {
  .contentContainer {
    width: 60% !important;
  }
}

@media (min-width: 1024px) {
  .contentContainer {
    width: 40% !important;
  }
}

@media (min-width: 1280px) {
  .contentContainer {
    width: 35% !important;
  }
}

@media (min-width: 1536px) {
  .contentContainer {
    width: 28% !important;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
}

.descriptionContainer {
  display: flex;
  justify-content: center;
}

.description {
  width: 90%;
}
