.slideDotImageContainer {
  text-align: center;
  margin-top: 10px;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.slideDotImageContainer::-webkit-scrollbar {
  display: none;
}

.circleDotImageContainer {
  text-align: center;
  padding: 0.5rem 0;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.circleDotImageContainer::-webkit-scrollbar {
  display: none;
}

.slideDot {
  margin: none;
  border: none;
  background: none;
  height: 40px;
  width: 40px;
  box-sizing: content-box;
}

.slideDotImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
  box-shadow:
    0 10px 8px rgb(0 0 0 / 0.04),
    0 4px 3px rgb(0 0 0 / 0.1);
  border: none;
  border-radius: 2px;
}

.carouselImgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.slideButton {
  position: absolute;
  top: 40%;
  border: none;
  background: none;
  opacity: 0.4;
  color: rgb(220, 220, 220);
}

.slideBackButton {
  left: 10px;
}

.slideNextButton {
  right: 7px;
}

.slideImage {
  margin: auto;
  height: 100%;
}

.circleDot {
  border: none;
  background: none;
  padding: 0.2rem;
}
