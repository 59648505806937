.buttonContainer {
  padding: 5px 0;
}

.buttonContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
}

.descriptionContainer {
  align-items: center;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex: 1;
}

.textBox {
  flex: 1;
  text-align: left;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 3.5px;
}
