.loading {
  display: flex;
  top: 0em !important;
  left: 0em !important;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  padding: 1em;
  background-color: white;
  opacity: 1;
  line-height: 1;
  animation-fill-mode: both;
  animation-duration: 0.5s;
  transition: background-color 0.5s linear;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  will-change: opacity;
  z-index: 1000;
}
